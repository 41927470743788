<template>
    <div>

        <v-alert type="error" colored-border border="left" dense v-if="error.state">{{ error.message }}</v-alert>

        <v-text-field
            v-model="search"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            hide-details
            clearable
            dense
            class="mx-5 mt-3"
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="comments"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
            :mobile-breakpoint="0"
            sort-by="date"
            sort-desc
            must-sort
            @click:row="rowClick"
            class="elevation-2"
        >
            <template v-slot:item.repeater="{ item }">
                <router-link :to="`/repeater/${item.repeater}`">{{ item.repeater }}</router-link>
            </template>

            <template v-slot:item.date="{ item }">
                <span
                    v-if="item.date">{{ $moment.utc(item.date).format('M/D/YYYY') }} <small>({{ $moment.utc(item.date).fromNow() }})</small></span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";

    export default {
        props: ['user'],

        data: () => ({
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'Username', value: 'username'},
                {text: 'Callsign', value: 'callsign'},
                {text: 'Email', value: 'email'},
                {text: 'Comment', value: 'comment'},
                {text: 'Date', value: 'date'},
                {text: 'Repeater', value: 'repeater'},
            ],
            comments: [],
            total: null,
            search: null,
            loading: false,
            options: {},
            showOldListings: true,
            showOfflineListings: true,
            error: {
                state: false,
                message: null
            }
        }),

        watch: {
            search() {
                this.fetchComments();
            },

            options: {
                handler() {
                    this.fetchComments();
                },
                deep: true
            }
        },

        methods: {
            async fetchComments() {
                try {
                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/comments?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.comments = response.data.items;
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        this.error.message = 'An error occurred while searching for comments.';
                        this.error.state = true;
                    }
                }
            },

            rowClick() {

            }
        },

        mounted() {
            // this.fetchComments();

            if (this.$router.currentRoute.name === 'admin-comments' && this.$router.currentRoute.params.id) this.search = this.$router.currentRoute.params.id;
        }
    }
</script>
