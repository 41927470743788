<template>
    <v-container fluid>
        <h1 class="page-title font-weight-light">Admin Panel</h1>

        <div v-if="user.authenticated && isAdmin(user.role)">
            <v-tabs
                v-model="tab"
                color="white"
                background-color="rfaccent2"
                fixed-tabs
                centered
                icons-and-text
            >
                <v-tab>
                    Statistics
                    <v-icon>mdi-chart-areaspline</v-icon>
                </v-tab>
                <v-tab>
                    Users
                    <v-icon>mdi-account-group</v-icon>
                </v-tab>
                <v-tab>
                    Repeaters
                    <v-icon>mdi-radio-tower</v-icon>
                </v-tab>
                <v-tab>
                    Comments
                    <v-icon>mdi-comment-multiple</v-icon>
                </v-tab>
                <v-tab>
                    Reports
                    <v-icon>mdi-comment-multiple</v-icon>
                </v-tab>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item>
                        <Stats :user="user"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Users :user="user"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Repeaters :user="user"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Comments :user="user"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Reports :user="user"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>

        <div v-else>
            <v-alert type="error" prominent class="mt-5">
                You must be an administrator to view this page.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
    import Stats from '@/components/Admin/Stats.vue';
    import Users from '@/components/Admin/Users.vue';
    import Repeaters from '@/components/Admin/Repeaters.vue';
    import Comments from '@/components/Admin/Comments.vue';
    import Reports from '@/components/Admin/Reports.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {Stats, Users, Repeaters, Comments, Reports},

        data: () => ({
            tab: null,
        }),

        methods: {
            isAdmin(role) {
                return role === 0;
            }
        },

        mounted() {
            useTitle(`Admin Panel - myGMRS.com`);

            switch (this.$router.currentRoute.name) {
                case 'admin-stats':
                    this.tab = 0;
                    break;
                case 'admin-users':
                    this.tab = 1;
                    break;
                case 'admin-repeaters':
                    this.tab = 2;
                    break;
                case 'admin-comments':
                    this.tab = 3;
                    break;
                case 'admin-reports':
                    this.tab = 4;
                    break;
            }

        }
    }
</script>
